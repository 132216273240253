import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

export class CreditCard {
  number: string;
  securityCode: string;
  expirationDate: string;
  name: string;
  constructor() { }
}

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor() { }

  obtenerTipoTarjeta(numeroTarjeta: string) {
    numeroTarjeta = !this.esNulo(numeroTarjeta) ? numeroTarjeta.toString() : '';
    let longitud = !this.esNulo(numeroTarjeta) ? numeroTarjeta.length : 0;

    if (longitud < 0 || longitud > 16) {
      return 'UNDEFINED';
    } else if (numeroTarjeta.startsWith('4')) {
      return "VISA";
    } else if (numeroTarjeta.startsWith('34') || numeroTarjeta.startsWith('37')) {
      return "AMEX";
    } else if (numeroTarjeta.startsWith('51') || numeroTarjeta.startsWith('52') || numeroTarjeta.startsWith('53') || numeroTarjeta.startsWith('54') || numeroTarjeta.startsWith('55')) {
      return "MASTERCARD";
    } else {
      return "UNDEFINED";
    }
  }

  esTarjetaValida(numeroTarjeta: string) {
    numeroTarjeta = !this.esNulo(numeroTarjeta) ? numeroTarjeta.toString() : '';
    let longitud = !this.esNulo(numeroTarjeta) ? numeroTarjeta.length : 0;

    if (longitud < 13 || longitud > 16) {
      return false;
    } else {
      return this.isValid(numeroTarjeta, longitud);
    }
  }

  esNulo(valor) {
    return (valor === undefined || valor === null);
  }

  isValid(ccNum, charCount) {
    var double = true;
    var numArr = [];
    var sumTotal = 0;
    for (let i = 0; i < charCount; i++) {
      var digit = parseInt(ccNum.charAt(i));

      if (double) {
        digit = digit * 2;
        digit = this.toSingle(digit);
        double = false;
      } else {
        double = true;
      }
      numArr.push(digit);
    }

    for (let i = 0; i < numArr.length; i++) {
      sumTotal += numArr[i];
    }
    var diff = eval((sumTotal % 10).toString());
    return (diff == "0");
  }

  toSingle(digit) {
    if (digit > 9) {
      var tmp = digit.toString();
      var d1 = parseInt(tmp.charAt(0));
      var d2 = parseInt(tmp.charAt(1));
      return (d1 + d2);
    } else {
      return digit;
    }
  }

  encriptarDatos(datosTarjeta: CreditCard, salt: string): string {
    datosTarjeta.name = datosTarjeta.name.toUpperCase();
    let datosTarjetaString = JSON.stringify(datosTarjeta);
    let hash = CryptoJS.AES.encrypt(datosTarjetaString, salt.trim()).toString();
    /*console.log('Salt', salt);
    console.log('datosTarjeta', datosTarjeta);
    console.log('datosTarjetaString', datosTarjetaString);
    console.log('Encriptado', hash);*/
    return hash;
  }
}
