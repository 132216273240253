import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carruselfooter',
  templateUrl: './carruselfooter.component.html',
  styleUrls: ['./carruselfooter.component.css']
})
export class CarruselfooterComponent implements OnInit {

  constructor() { }


  public carruselImages: any[] = [
    {
      id: 1,
      src: '/assets/img/banners/footer/BANNERSECUNDARIO.jpg',
      active: 'active'
    }
    // {
    //   id: 2,
    //   src: '/assets/img/banners/footer/bannersecundario2.jpg',
    //   active: ''
    // },
    //{
    //  id: 2,
    //  src: '/assets/img/banners/footer/bannersecundario3.jpg',
    //  active: ''
    //},
    // {
    //   id: 4,
    //   src: '/assets/img/banners/footer/bannersecundario4.jpg',
    //   active: ''
    // },
  ];

  ngOnInit(): void {
  }
}
