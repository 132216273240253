import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-carrusel-venta-login',
  templateUrl: './carrusel-venta-login.component.html',
  styleUrls: ['./carrusel-venta-login.component.css']
})
export class CarruselVentaLoginComponent implements OnInit {

  constructor() {}

  public rutaImagenes: string = '/assets/img/banners/ventalogin/';
  public rutaImagenesHotTravelInactivo: string = '/assets/img/banners/ventalogin/';

  public bannerHotTravelInactivo: string[] = ['BANNERLOGIN.jpg'];
  public bannerHotTravelActivo: string[] = [
    'PRIMERA.jpg',
    'AEROPUERTO.jpg',
    'EBUS.jpg',
    'INTERMEDIO.jpg',
  ];

  public esActivoPromocionLogin: boolean = false;

  public carruselImagesNow: any[] = this.esActivoPromocionLogin ?
    this.bannerHotTravelActivo.map((imageName, index) => ({
      id: index + 1,
      src: `${this.rutaImagenes}${imageName}`,
      active: index === 0 ? 'active' : 'inactive',
    })) :
    this.bannerHotTravelInactivo.map((imageName, index) => ({
      id: index + 1,
      src: `${this.rutaImagenesHotTravelInactivo}${imageName}`,
      active: index === 0 ? 'active' : 'inactive',
    }));

  ngOnInit(): void {
  }
}
