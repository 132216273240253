<div class="monserrat animated fadeIn">

  <div class="modal-header modal-header-er" style="border-radius: 20px;">
    <h4 class="modal-title"><b>{{paquete.nombre}}</b></h4>
    <button type="button" class="close text-light" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="offset-9 cards-img" style="z-index: 99; position: absolute; margin-top: -62px; margin-left: 40em;">
      <img src="assets/img/cards.jpg">
    </div>
    <form class="row" [formGroup]="paymenntForm" (ngSubmit)="payment()" novalidate>

      <div class="col-md-6">
        <div class="row">
          <div class="form-group col-md-12">
            <label for="nombre">Nombre completo <b><span style="font-size: 9px;">(Como aparece en tu tarjeta de
                  crédito/débito)</span></b></label>
            <input type="email" class="form-control" id="nombre"
              onkeydown="return /[a-zA-Z ñÑá-úÁ-Ú. ]/i.test(event.key)" formControlName="nombreCliente"
              [ngClass]="{ 'is-invalid': submitted && f.nombreCliente.errors }">
            <div *ngIf="submitted && f.nombreCliente.errors" class="invalid-feedback">
              <div *ngIf="f.nombreCliente.errors.required">Nombre requerido</div>
              <div *ngIf="f.nombreCliente.errors.pattern">Solo letras</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <label for="correo">Correo</label>
            <input type="email" class="form-control" id="correo"
                   [readOnly]="isVentaLogin"
                   placeholder="name@example.com" formControlName="correo"
              [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
            <div *ngIf="submitted && f.correo.errors" class="invalid-feedback">
              <div *ngIf="f.correo.errors.required">Correo requerido</div>
              <div *ngIf="f.correo.errors.email">Correo invalido</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <label for="correo">Fecha Nacimiento</label>
            <input type="date" class="form-control" id="birhDate"
              (blur)="validarFechaMenorActual($event.target.value, $event.type)"
              (keyup)="validarFechaMenorActual($event.target.value, $event.type)" [max]="maxDate" [min]="minDate"
              placeholder="" formControlName="birhDate" [ngClass]="{ 'is-invalid': submitted && f.birhDate.errors }">
            <div *ngIf="submitted && f.birhDate.errors" class="invalid-feedback">
              <div *ngIf="f.birhDate.errors.required">Fecha de nacimiento requerido</div>
              <div *ngIf="f.birhDate.errors.date">Fecha invalida</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6">
            <label for="telefono">Teléfono</label>
            <input class="form-control" type="text" placeholder="(555)-555-5555" id="telefono"
              formControlName="telefono" [specialCharacters]="[ '(' ,'-',' ',')' , '\\' ]" mask="(000)-000-0000"
              [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }">
            <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback">
              <div *ngIf="f.telefono.errors.required">Teléfono requerido</div>
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="cp">Código postal</label>
            <input minlength="5" maxlength="5" class="form-control" type="text" placeholder="55555" id="cp"
              formControlName="codigoPostal" mask="00000"
              [ngClass]="{ 'is-invalid': submitted && f.codigoPostal.errors }">
            <div *ngIf="submitted && f.codigoPostal.errors" class="invalid-feedback">
              <div *ngIf="f.codigoPostal.errors.required">Código postal requerido</div>
              <div *ngIf="f.codigoPostal.errors.min || f.codigoPostal.errors.max">El tamaño del código postal son 5
                dígitos
              </div>
            </div>
          </div>

          <!-- <div class="form-group col-md-6">
            <label for="cp">Código postal</label>
            <input class="form-control" min="11111" max="99999" type="number" id="cp" formControlName="codigoPostal"
              [ngClass]="{ 'is-invalid': submitted && f.codigoPostal.errors }">
            <div *ngIf="submitted && f.codigoPostal.errors" class="invalid-feedback">
              <div *ngIf="f.codigoPostal.errors.required">Cdigo postal requerido</div>
              <div *ngIf="f.codigoPostal.errors.min || f.codigoPostal.errors.max">El tamaño del código postal son 5 dígitos
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="col-md-6">
        <label>Tipo de pago</label>
        <ng-container class="col-md-6">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="tipoPago" id="pagoTarjeta" checked
              (change)="seleccionarTipoPago('tarjeta')">
            <label class="form-check-label" for="pagoTarjeta">
              Tarjeta de crédito / débito
            </label>
          </div>
          <div class="form-check" *ngIf="aceptaPagoEfectivo">
            <input class="form-check-input" type="radio" name="tipoPago" id="tipoPagoEfectivo"
              (change)="seleccionarTipoPago('efectivo')">
            <label class="form-check-label" for="tipoPagoEfectivo">
              Pago en efectivo
            </label>
          </div>
        </ng-container>
        <br>
        <label *ngIf="!isPagoTarjeta"> <b>En:</b></label>
        <br *ngIf="!isPagoTarjeta">
        <ng-template id="pagosEfectivo" [ngIf]="!isPagoTarjeta">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-6 " *ngFor="let partner of partnersPagosEfectivo">
                <input class="form-check-input" type="radio" name="pagoEfectivo" id="pagoEfectivo" value="partner.id"
                  (change)="seleccionarPartnerPagoEfectivo(partner.partner.id)">
                <img [src]="partner.partner.urlImg" [alt]="partner.partner.descripcion" width="100px">
              </div>
            </div>
          </div>

        </ng-template>

        <ng-template class="col-md-6" [ngIf]="isPagoTarjeta">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="inlineFormInputGroupUsername">Número de tarjeta</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <fa-icon size="1x" [icon]="cardIcon"></fa-icon>
                  </div>
                </div>
                <input type="text" class="form-control" id="inlineFormInputGroupUsername" mask="0000000000000000"
                  placeholder="xxxx-xxxx-xxxx-xxxx" minlength="1" maxlength="16" formControlName="numeroTarjeta"
                  [ngClass]="{ 'is-invalid': submitted && f.numeroTarjeta.errors }"
                  (ngModelChange)="obtenerTipoTarjeta()" (focusout)="validarTarjeta()">
                <div *ngIf="submitted && f.numeroTarjeta.errors" class="invalid-feedback">
                  <div *ngIf="f.numeroTarjeta.errors.required">Número de tarjeta requerida</div>
                  <div *ngIf="f.numeroTarjeta.errors.minlength || f.numeroTarjeta.errors.maxlength">Numero de tarjeta
                    invalida
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: -6px;">
            <div class="form-group col-md-6">
              <label for="fecha" class="col-form-label">Fecha vigencia</label>
              <input type="text" class="form-control" id="fecha" [specialCharacters]="[ '/' , '\\' ]" mask="MM/AA"
                placeholder="MM/AA" formControlName="fechaExpiracion"
                [ngClass]="{ 'is-invalid': submitted && f.fechaExpiracion.errors }">
              <div *ngIf="submitted && f.fechaExpiracion.errors" class="invalid-feedback">
                <div *ngIf="f.fechaExpiracion.errors.required">Fecha de expiración requerida</div>
                <div *ngIf="f.fechaExpiracion.errors.minlength || f.fechaExpiracion.errors.maxlength">Fecha invalida
                </div>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label for="cvv" class="col-form-label">Código seguridad</label>
              <input type="password" minlength="3" [attr.maxlength]="longitudCvv" class="form-control" [attr.mask]="mascaraCvv" id="cvv"
                placeholder="CVV" formControlName="cvv" [ngClass]="{ 'is-invalid': submitted && f.cvv.errors }">
              <div *ngIf="submitted && f.cvv.errors" class="invalid-feedback">
                <div *ngIf="f.cvv.errors.required">Número de seguridad requerido</div>
                <div *ngIf="f.cvv.errors.min || f.cvv.errors.max">El tamaño del código de seguridad son 3 dígitos</div>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="form-group col-md-12">
          <br />
          <label for="lms">Número de afiliado punto abordo <b><span style="font-size: 9px;">(no
                obligatorio)</span></b></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <img src="assets/img/tarjeta_pa.png">
              </div>
            </div>
            <input type="text" class="form-control" id="lms" formControlName="lmsCliente" autocomplete="off">
          </div>
        </div>

        <h3 class="total-pago">Total: {{paquete.precioPromocion | currency }} MXN</h3>
      </div>


      <div class="row">
        <div class="col-md-12 col-offset-6">
          <div class="form-group col-md-12 col-offset-6">
            <div class="form-check">
              <input type="checkbox" class="form-check-input check-terminos" id="checkTerminos"
                formControlName="aceptaTerminos">
              <button type="button" class="btn btn-link" (click)="showRestricciones(paquete)">Acepto los t&eacute;rminos
                y
                condiciones</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer full-width">
        <div class="col-md-12 text-center">
          <button type="submit" class="btn btn-primary col-md-4 mr-4"
            [ngClass]="{'btn-outline-primary disabled': !paymenntForm.value.aceptaTerminos || payPackage}"
            [disabled]="!paymenntForm.value.aceptaTerminos || payPackage">
            <fa-icon [icon]="faShoppingCart"></fa-icon> Pagar
            <span *ngIf="payPackage" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
          <button type="button" class="btn btn-dark col-md-4" (click)="activeModal.close('Close click')">
            <fa-icon [icon]="faBackward"></fa-icon> Regresar
          </button>
        </div>
      </div>
    </form>
  </div>

</div>
