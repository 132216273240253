<div class="col-md-12 col-sm-12 " style="margin-top: 40px;">
  <div class="row">
    <div class="col-md-2"> </div>
    <div class="col-md-8 d-flex justify-content-center">
      <img src="/assets/img/bnblogin/perfil.png" id="imagenContacto" style="width:80px; height: 80px;" alt="">
    </div>
    <div class="col-md-2"> </div>
  </div>
  <div class="main" style="margin-top: 1em;">
    <div class="container ">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="form-group col-md-12">
          <input type="email" class="form-control login-input" id="correologin" placeholder="Correo" formControlName="correo"
                 [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
          <div *ngIf="submitted && f.correo.errors" class="invalid-feedback">
            <div *ngIf="f.correo.errors.required">Correo requerido</div>
            <div *ngIf="f.correo.errors.email">Correo invalido</div>
          </div>
        </div>

        <div class="form-group col-md-12">
          <input class="form-control login-input" type="text" placeholder="Teléfono" id="telefonologin"
                 formControlName="telefono" [specialCharacters]="[ '(' ,'-',' ',')' , '\\' ]" mask="(000)-000-0000"
                 [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }">
          <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback">
            <div *ngIf="f.telefono.errors.required">Teléfono requerido</div>
          </div>
        </div>

        <div class="form-group col-md-12">
          <button type="submit" class="btn form-control boton-login">Entrar</button>
        </div>

        <div class="form-group col-md-12  justify-content-center">
          <div class="border-login"></div>
        </div>
      </form>
    </div>
  </div>
</div>
