import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MensajeService} from "../../services/mensajes.service";
import Swal from "sweetalert2";
import {AppService} from "../../services/app.service";

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  messageForm : FormGroup;
  submitted = false;
  public sendedMessage : boolean = false;

  constructor(private formBuilder: FormBuilder,
              private appService: AppService,
              private mensajeService: MensajeService) { }

  ngOnInit(): void {
    this.messageForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      telefono:['', Validators.required],
      mensaje:['', Validators.required],
      estatus:[0,null]
    });
  }

  sendMessage() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.messageForm.invalid) {
      return;
    }
    this.sendedMessage = true;
    this.appService.checkCredentials().then(value => {
      this.mensajeService.saveMensaje(this.messageForm.value).subscribe(value => {
        this.messageForm.reset();
        this.submitted = false;
        this.sendedMessage = false;
        Swal.fire('¡Gracias por tu mensaje!','En breve unos de nuestros asesores se estará poniendo en contigo para resolver tus dudas, comentarios o sugerencias.','success');
      }, error => {
        Swal.fire('Ups..','Error al enviar el mensaje, intentar mas tarde!','error');
        this.sendedMessage = false;
      });
    });
  }

  get f() {
    return this.messageForm.controls;
  }

}
