import { Component, OnInit } from '@angular/core';
import { faFacebookF, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Swal from "sweetalert2";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls:  ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  faTwitter = faTwitter;
  faFacebookF = faFacebookF;
  classPopUp = '';
  versionSistema: String = environment.ENVIRONMENT.VERSION;
  rutaIconoX: string = '../../../../assets/img/X.svg';
  rutaIconoInstagram: string = '../../../../assets/img/bnblogin/intagram.png';
  rutaIconoFacebook: string = '../../../../assets/img/bnblogin/facebook.png';

  constructor() { }

  ngOnInit(): void {
  }

  showPopUp(){
    Swal.fire({
      title :'Facturación',
      html : '<div style="text-align: justify">Si requieres facturar tu compra, te pedimos proporcionar los siguientes datos al correo: <strong style="color: #005cb9;">facturacionbonobus@estrellaroja.com.mx</strong> <br><br> <div style="text-align: left; margin-left: 1em;">• Cédula de identificación fiscal <br>• Correo Electrónico <br>• Número de paquetes adquiridos <br>• Importe <br> • Forma de pago (débito o crédito)</div></div>',
      imageAlt: 'success',
      imageHeight: 70,
      imageWidth: 70
    });
  }
}
