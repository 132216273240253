import { Component, OnInit } from '@angular/core';
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {LoginComponent} from "../../modals/login/login.component";
import {Router} from "@angular/router";
import {SharedService} from "../../../services/shared.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls:  ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  modalOptions: NgbModalOptions;
  email: string = localStorage.getItem('1AzsRdds445ljGD');  
  rutaIconoCheck: string = '../../../../assets/img/check-icon.svg';

  constructor(private modalService: NgbModal,
              public sharedService: SharedService,
              private route: Router) { }
  ngOnInit( ): void {
    if (this.email !== null && this.email !== undefined ){
      this.sharedService.showMenuLogin = false;
    }
    this.modalOptions = {
      centered: true,
      windowClass : 'login'
    };
  }

  showModalLogin() {
    const modalRef = this.modalService.open(LoginComponent, this.modalOptions);
  }

  closeSession() {
    localStorage.removeItem('1AzsRdds445ljGD');
    this.route.navigate(['/home']);
    this.sharedService.showMenuLogin = true;
    Swal.fire('', 'Se ha cerrado sesión correctamente', 'success');
  }


}
