<div id="carruselFooterId" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner" >
    <div class="carousel-item text-center" *ngFor="let image of carruselImages" [ngClass]="image.active">
      
        <img class="w-100 height-carrousel img-fluid" src="{{image.src}}" alt="Slide 1">
      
    </div>
  </div>
  <a class="carousel-control-prev" href="#carruselFooterId" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carruselFooterId" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
