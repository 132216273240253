import {Component, Input, OnInit} from '@angular/core';
import {faBackward} from "@fortawesome/free-solid-svg-icons";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-instrucciones',
  templateUrl: './instrucciones.component.html',
  styleUrls: ['./instrucciones.component.css']
})
export class InstruccionesComponent implements OnInit {

  faBackward = faBackward;
  constructor(public activeModal: NgbActiveModal) { }

  @Input() paquete;
  @Input() instrucciones;

  ngOnInit(): void {
  }

}
