import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-carrusel',
  templateUrl: './carrusel.component.html',
  styleUrls: ['./carrusel.component.css']
})
export class CarruselComponent implements OnInit {

  constructor() { }

  public rutaImagenes: string = '/assets/img/banners/ventalogin/';
  public rutaImagenesHotTravelInactivo: string = '/assets/img/banners/ventageneral/';

  public bannerHotTravelInactivo: string[] = ['BANNERBONOBUS.jpg'];
  public bannerHotTravelActivo: string[] = [
    'PRIMERA_BANNER.jpg',
    'DIRECTOAJUSTE_BANNER.jpg',
    'AERO_BANNER.jpg',
    'EBUS_BANNER.jpg',
    'SANMARTIN_BANNER.jpg',
  ];

  public esActivoHotTravel: boolean = false;


  public carruselImagesNow: any[] = this.esActivoHotTravel
    ? this.bannerHotTravelActivo.map((imageName, index) => ({
        id: index + 1,
        src: `${this.rutaImagenes}${imageName}`,
        active: index === 0 ? 'active' : 'inactive',
      }))
    : this.bannerHotTravelInactivo.map((imageName, index) => ({
        id: index + 1,
        src: `${this.rutaImagenesHotTravelInactivo}${imageName}`,
        active: index === 0 ? 'active' : 'inactive',
      }));

  ngOnInit(): void {
  }
}
