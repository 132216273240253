import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {catchError, map, share} from "rxjs/operators";
import {throwError} from "rxjs";
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class PartnersPagosService {
  apiUrl = environment.SIVER_API.URL;

  constructor(private http: HttpClient) { }

  public getPaquetesPartnersByCuponId(cuponId: number){

    return this.http.get<any[]> (`${this.apiUrl}cuponpartner/cuponpartnerdisponible/${cuponId}`,)
    .pipe( map((data : Object[]) => {
      return data.map((r : any) => r)
    }))
    .pipe(share())
    .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    Swal.fire('Ups!', 'Error al obtener el pago en efectivo favor intetar nuevamente <br> ', 'error')
    return throwError(errorMessage);
  }

}
