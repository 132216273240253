import {Component, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import Swal from "sweetalert2";
import { UsuariobonobusService } from '../../../services/usuariobonobus.service';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-home-login-invitation',
  templateUrl: './home-login-invitation.component.html',
  styleUrls: ['./home-login-invitation.component.css']
})
export class HomeLoginInvitationComponent implements OnInit {

  submitted = false;
  constructor(
    private usuariobonobusService: UsuariobonobusService,
    private fb: FormBuilder,
    private route: Router,
    private appService: AppService,
  ) { }

  subscriptionList = new Array<Subscription>();
  form: FormGroup;
  
  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      correo: ['', [Validators.required]],
      telefono: ['', [Validators.required]]
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    var bodyElement = document.body;
    bodyElement.classList.add('login');
    this.appService.checkCredentials().then(value => {
      this.usuariobonobusService.login(this.form.value.correo, this.form.value.telefono).subscribe(loginResult => {
        console.log(loginResult);
        this.route.navigate(['/homeuser']);
        Swal.fire('', 'Se ha iniciado sesión correctamente', 'success');
      });
    }).catch(error => {
      console.error(error);
      this.submitted = false;
      Swal.fire('Error', 'No se pudieron validar los permisos del usuario, refresque su pagina', 'error');
    });
  }

  closeModal() {
  }

  get f() {
    return this.form.controls;
  }
}