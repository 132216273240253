<div id="carouselIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li *ngFor="let carrusel of carruselImagesNow" data-target="#carouselIndicators" [ngClass]="carrusel.active"></li>
      <!--<li data-target="#carouselIndicators" data-slide-to="1"></li>
      <li data-target="#carouselIndicators" data-slide-to="2"></li>
      <li data-target="#carouselIndicators" data-slide-to="3"></li>
      <li data-target="#carouselIndicators" data-slide-to="4"></li>-->
    </ol>
    <div class="carousel-inner margin-top-carrusel">
      <div class="carousel-item text-center" *ngFor="let carrusel of carruselImagesNow" [ngClass]="carrusel.active">
        <img class="img-fluid"  size="200x200" [src]="carrusel.src" alt="Slide 1">
      </div>
    </div>
     <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a> 
  </div>
