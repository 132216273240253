import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, share} from "rxjs/operators";
import {Observable} from "rxjs";

class Ip {
  constructor(public ip : string) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class IpService {

  public reposObserver : Observable<Ip[]>;

  constructor(private http:HttpClient) { }
  public getIPAddress()
  {
    return this.http.get("https://api.ipify.org/?format=json")
    .pipe(share());
  }
}
