<app-header-login></app-header-login>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');

</style>

<div class="container">
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8 margin-top-formulario">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center align-items-center">
          <div class="row">
            <div class="col-md-12">
              <!-- Aquí puedes colocar tu imagen -->
              <img src="../../assets/img/bnblogin/bonoLoginUnico.png" alt="Imagen única" class="bonoLoginUnico">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center align-items-center">
          <div class="col-md-12" style="text-align: center;">
            <!-- Aquí puedes colocar tu párrafo -->
            <p class="cabin-fuente text-center parrafo">Si recibiste un correo
              o sms con una promoción exclusiva, ingresa tus datos y descúbrela.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 formulario"
          style="background-color: #f6f6f6; padding: 2% 20px; border: 1px solid transparent; border-radius: 10px;">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 d-flex justify-content-center">
              <img src="/assets/img/bnblogin/perfil.svg" id="imagenContacto" alt="">
            </div>
            <div class="col-md-2"></div>
          </div>
          <div class="main" style="margin-top: 1em;">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="form-group row">
                <div class="col-md-6 contenedor">
                  <input type="email" class="registro form-control login-input cabin-fuente" id="correologin"
                    placeholder="Correo" formControlName="correo"
                    [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                  <div *ngIf="submitted && f.correo.errors" class="invalid-feedback">
                    <div *ngIf="f.correo.errors.required">Correo requerido</div>
                    <div *ngIf="f.correo.errors.email">Correo inválido</div>
                  </div>
                </div>
                <div class="col-md-6 contenedor">
                  <input class="registro form-control login-input cabin-fuente" type="text" placeholder="Teléfono"
                    id="telefonologin" formControlName="telefono" [specialCharacters]="[ '(' ,'-',' ',')' , '\\' ]"
                    mask="(000)-000-0000" [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }">
                  <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback">
                    <div *ngIf="f.telefono.errors.required">Teléfono requerido</div>
                  </div>
                </div>
              </div>
              <div class="form-group row justify-content-center">
                <div class="col-md-7">
                  <!-- Cambiado a 4 para ocupar un tercio del espacio disponible -->
                  <button type="submit" class="btn form-control boton-login cabin-fuente">Entrar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>
</div>
<app-ayuda-pasos></app-ayuda-pasos>
<app-logos></app-logos>
<app-footer-venta-login></app-footer-venta-login>
