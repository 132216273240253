import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {faWindowClose, faCheck} from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-restricciones',
  templateUrl: './restricciones.component.html',
  styleUrls: ['./restricciones.component.css']
})
export class RestriccionesComponent implements OnInit {

  @Input() paquete;
  @Input() restricciones;
  faCheck = faCheck;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  textAreaAdjust(o){
    o.style.height = "1px";
    o.style.height = (25+o.scrollHeight)+"px";
    console.log('textAreaAdjust');
  }
}
