<div class="monserrat animated fadeIn">

  <div class="modal-header modal-header-er">
    <h4 class="modal-title">Paquete promocional <b>{{paquete.nombre}}</b></h4>
    <button type="button" class="close text-light" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <img _ngcontent-wfu-c97="" [src]="'assets/img/' + paquete.servicio + '.png'" alt="" width="100%">
    <div [innerHTML]="restricciones"></div>
  </div>

  <div class="modal-footer full-width">
    <div class="col-md-12 text-center">
      <button type="button" class="btn btn-dark col-md-4" (click)="activeModal.close('Close click')">
        <fa-icon [icon]="faCheck"></fa-icon> DE ACUERDO
      </button>
    </div>
  </div>

</div>
