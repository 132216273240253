import {NgModule} from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./components/home/home.component";
import {HomeVentaLoginComponent} from "./components/login/home-venta-login/home-venta-login.component";
import { HomeLoginInvitationComponent } from "./components/login/home-login-invitation/home-login-invitation.component";

const routes : Routes = [
    { path: 'home', component: HomeComponent},
    { path: 'homeuser', component: HomeVentaLoginComponent},
    { path: 'homelogin', component: HomeLoginInvitationComponent},
    {path: '', component: HomeComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutes { }
