import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ayuda-pasos',
  templateUrl: './ayuda-pasos.component.html',
  styleUrls: ['./ayuda-pasos.component.css']
})
export class AyudaPasosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
