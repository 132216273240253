import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  validarObjeto(objeto): Boolean {
    if (objeto === undefined || objeto === null) {
      return false;
    } else {
      return true;
    }
  }

  validarString(string): Boolean {
    if (string === '' || string === undefined || string === null || string === 'null') {
      return false;
    } else {
      return true;
    }
  }

  isSuccessResponse(response): Boolean {
    return response.responseCode === 200;
  }

}
