import { Component, OnInit } from '@angular/core';
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-header-login',
  templateUrl: './header-login.component.html',
  styleUrls: ['./header-login.component.css']
})
export class HeaderLoginComponent implements OnInit {


  modalOptions: NgbModalOptions;
  email: string = localStorage.getItem('1AzsRdds445ljGD');  
  rutaIconoCheck: string = '../../../../assets/img/check-icon.svg';

  constructor(private modalService: NgbModal,
              public sharedService: SharedService,
              private route: Router) { }
  ngOnInit( ): void {
    if (this.email !== null && this.email !== undefined ){
      this.sharedService.showMenuLogin = false;
      this.route.navigate(['/homeuser']);
    }
    this.modalOptions = {
      centered: true,
      windowClass : 'login'
    };
  }


  closeSession() {
    localStorage.removeItem('1AzsRdds445ljGD');
    this.route.navigate(['/homeuser']);
    this.sharedService.showMenuLogin = true;
    Swal.fire('', 'Se ha cerrado sesión correctamente', 'success');
  }


}
