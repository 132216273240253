<div class="main" style="margin-top: 1em;">
  <div class="main_text col-md-12" id="contacto" data-aos="fade-right" style="
   background-color: #eeeff1;
    border-radius: 2em !important;">
    <section class="page-section" style=" background-color: #eeeff1;">
      <div class="container">
        <div class="text-center">
          <h2 class="section-heading text-uppercase">Regístrate</h2>
          <h3 class="section-subheading text-center">
            <strong>¿Tienes alguna duda sobre nuestro bono bus?</strong>
            <br>
            Contáctanos y con mucho gusto te estaremos respondiendo.
          </h3>
        </div>
      </div>
    </section>
    <div class="container" style="margin-bottom: 1em">
      <form name="sentMessage" [formGroup]="messageForm" (ngSubmit)="sendMessage()" novalidate>
        <div class="row align-items-stretch mb-2">
          <div class="col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" id="nombre" formControlName="nombre" placeholder="Nombre *"
                     [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
              <p *ngIf="submitted && f.nombre.errors" class="help-block text-invalid">Por favor, introduce tu nombre</p>
            </div>
            <div class="form-group">
              <input type="correo" class="form-control" id="correo" formControlName="correo" placeholder="Correo *"
                     [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
              <p *ngIf="submitted && f.correo.errors" class="help-block text-invalid">Por favor, introduce un correo valido
              </p>
            </div>
            <div class="form-group mb-md-0">
              <input class="form-control" type="text" placeholder="Telefono (555)-555-5555 *" id="telefono"
                     formControlName="telefono" [specialCharacters]="[ '(' ,'-',' ',')' , '\\' ]" mask="(000)-000-0000"
                     [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }">
              <p *ngIf="submitted && f.telefono.errors" class="help-block text-invalid">Por favor, introduce un telefono
                valido</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group form-group-textarea mb-md-0">
            <textarea class="form-control" rows="5" id="mensaje" placeholder="Escribe tu mensaje *"
                      formControlName="mensaje" [ngClass]="{ 'is-invalid': submitted && f.mensaje.errors }" maxlength="300"></textarea>
              <p *ngIf="submitted && f.mensaje.errors" class="help-block text-invalid">Por favor, introduce tu mensaje (máx 300 caracteres)
            </div>
          </div>
        </div>
        <div class="text-center">
          <button class="btn btn-primary text-uppercase btn-lg" id="sendMessageButton" type="submit"
                  [ngClass]="{'btn-outline-primary disabled': sendedMessage}"
                  [disabled]="sendedMessage">
            Enviar
            <span *ngIf="sendedMessage" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>

        </div>
      </form>
    </div>
  </div>
</div>
