import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import { CookieService } from 'ngx-cookie-service';
import Swal from "sweetalert2";
declare var toastr: any;

export class Foo {
  constructor(public id: number, public name: string) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private httpClient: HttpClient, private cookie: CookieService) { }

  public isLoggedIn = false;
  public fechaExpiracion;

  retrieveToken(): Promise<string> {
    return new Promise<string>((resolve , reject) =>{
      let headers = new HttpHeaders(
        {
          'Accept':'*/*',
          'Content-type': 'application/x-www-form-urlencoded',
          'Authorization': environment.AUTHENTICATE_API.AUTH_TYPE + ' ' + btoa(environment.AUTHENTICATE_API.AUTH_USERNAME + ':' + environment.AUTHENTICATE_API.AUTH_PASSWORD)
        });

      let body = new URLSearchParams();
      body.set('grant_type', 'password');
      body.set('username', environment.AUTHENTICATE_API.GRANT_USERNAME);
      body.set('password', environment.AUTHENTICATE_API.GRANT_PASSWORD);

      this.httpClient.post(environment.AUTHENTICATE_API.URL + 'oauth/token',
        body.toString()
        , { headers: headers })
        .pipe(catchError(this.handleError))
        .subscribe(value => {
            this.saveToken(value);
            resolve();
          },
          err => {
            this.httpClient.post(environment.AUTHENTICATE_API.URL + 'oauth/token',
              body.toString()
              , { headers: headers })
              .pipe(catchError(this.handleError))
              .subscribe(value => {
                  this.saveToken(value);
                  resolve();
                },
                err => {
                  Swal.fire('Error', 'Error al cargar los paquetes, intentar mas tarde', 'error')
                  reject("Error");
                });
          });
    });
  }

  saveToken(token) {
    let expiredDate = new Date();
    expiredDate.setSeconds(token.expires_in - 1);
    this.fechaExpiracion = expiredDate;
    this.cookie.set('access_token', token.access_token, expiredDate);
    this.cookie.set('fechaExpiracion', expiredDate.toString(), expiredDate);
    return;
  }


  checkCredentials() : Promise<string> {
    return new Promise<string>((resolve , reject) =>{
      let fechaExpiracion = this.cookie.get('fechaExpiracion');
      if(fechaExpiracion != ''){
        this.fechaExpiracion = new Date(fechaExpiracion);
        if (new Date() > this.fechaExpiracion){
          this.retrieveToken().then(value => resolve());
        }else {
          this.isLoggedIn = this.cookie.check('access_token');
          if(!this.isLoggedIn){
            this.retrieveToken().then(value => {resolve()});
          }
        }
        resolve();
      }else {
        this.retrieveToken().then(value => {resolve()});
      }
    });
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
