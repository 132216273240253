export const environment = {
  production: true,
  ENVIRONMENT: {
    NOMBRE_SISTEMA: 'Bono bus',
    VERSION: '2.3.10'
  },
  google: {
    analytics: {
      active: true,
      uaId: 'UA-168601105-1',
      logging : {
        debug: true,
        exceptions: true,
        verbose: true,
      }
    }
  },
  AUTHENTICATE_API: {
    URL: 'https://apicloud.estrellaroja.com.mx/authenticate/',
    AUTH_TYPE: 'Basic',
    AUTH_USERNAME: '72246d22-16f7-4bd3-ac38-08e6e292b8f5',
    AUTH_PASSWORD: '72246d22-16f7-4bd3-ac38-08e6e292b8f5',
    GRANT_USERNAME: 'siver',
    GRANT_PASSWORD: '2b46b3c505984152ecaee91f1d14654d'
  },
  SIVER_API: {
    URL: 'https://apicloud.estrellaroja.com.mx/siver/api/v1/'
  },
};
