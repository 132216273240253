<app-header></app-header>
<!-- Carruseles -->
<a href="#paquetes"><app-carrusel></app-carrusel></a>
<!-- Paquetes -->
<section class="page-section section-info" id="info">
  <div class="container" data-aos="fade-right" style="margin-top: 1em;">
    <div class="text-center">
      <h3 class="section-heading text-uppercase mb-4" id="bonobusTitulo">REGRESA A LA UNI CON BONO BUS.</h3>
      <h3 class="section-subheading margin-subheading">
        <p align="justify" style="line-height: 108%; margin-bottom: 0.28cm">La uni está de vuelta y nuestro bono bus también. Adquiere el tuyo para viajar entre San Martín y BUAP al mejor precio.</p>
        <p align="justify" style="line-height: 108%; margin-bottom: 0.28cm">Además, tu bono bus de regreso a la uni te sorprenderá con un descuento mayor en octubre. ¡Espéralo!</p>
        <p align="justify" style="line-height: 108%; margin-bottom: 0.28cm">Con estrella roja, la forma en la que viajas sí importa.</p>
    </h3>
    </div>
  </div>
</section>


<section class="page-section" id="paquetes">
  <div class="container margin-bonobus" data-aos="fade-right">
    <ul class="nav nav-tabs justify-content-center" id="pills-tab" role="tablist">
      <li *ngFor="let categoria of categoriasUnicas" class="nav-item">
        <a class="nav-link tabs-categoria" [ngClass]="{'active' : categoria === 'Todos'}"
          id="{{categoria.trim() + '-tab'}}" data-toggle="pill" href="{{'#'+categoria.trim()}}" role="tab"
          aria-controls="pills-1" aria-selected="true"
          (click)="filtrarPaquetes(categoria)"><b>{{categoria}}</b></a>
      </li>
    </ul>



    <div class="card-deck mb-3 text-center" style="margin-top: 1em;">
      <div *ngFor="let paquete of paquetesFiltrados" data-aos="zoom-in-left"
        class="card mb-4 shadow-sm overflow-hidden">
        <div class="card-header card-header-er">
          <img _ngcontent-wfu-c97="" [src]="'assets/img/' + paquete.servicio + '.png'" alt="" height="60px;">
          <h4 class="my-0 font-weight-normal" *ngFor="let titulo of paquete.titulos">{{titulo}}</h4>
          <h4 class="my-0 font-weight-normal">{{paquete.categoria.clave}}</h4>
        </div>
        <div class="card-body"
          [ngClass]="{'card-ebus': paquete.servicio === 'EBUS','card-aero': paquete.servicio === 'AEROPUERTO', 'card-er':paquete.servicio==='PULLMAN', 'card-intermedio-pista':paquete.servicio === 'INTERMEDIO PISTA','card-intermedio':paquete.servicio === 'INTERMEDIO','card-directo-ttp':paquete.servicio === 'DIRECTO TTP'||paquete.servicio==='DIRECTO'}">
          <h1 class="card-title text-white">
            {{paquete.precioPromocion | currency}} <br>
            <small> MXN</small>
          </h1>
          <ul class="list-unstyled mt-3 mb-4 text-white">
            <li>Precio original: {{paquete.precioOriginal | currency}} MXN</li>
            <li>Disponibles: <b>{{paquete.totalPaquetesDisponibles | number}}</b></li>
            <li>
              <fa-icon [icon]="faInfo"></fa-icon>
              <input (click)="showRestricciones(paquete)" class="btn text-white text-decoration-underline" type="button"
                value="Términos y Condiciones">
            </li>
            <li>
              <fa-icon [icon]="faBook"></fa-icon>
              <input (click)="showInstrucciones(paquete)" class="btn text-white" type="button"
                value="Instrucciones de Canje">
            </li>
          </ul>
          <button type="button" class="btn btn-lg btn-block btn-light" (click)="open(paquete)">Comprar</button>
        </div>
      </div>
    </div>
  </div>
</section>
<app-carruselfooter></app-carruselfooter>
<app-contacto></app-contacto>
<app-footer></app-footer>
