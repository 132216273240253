<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <a href="#page-top">
          <img src="../../../../assets/img/bnblogin/triangulos.svg" class="d-flex align-items-center" width="100%"
            height="100%" alt="" />
        </a>
      </div>
      <div class="col-md-3 footer-copyright">
        <label class="font-weight-bold">Copyright © Estrella Roja 2023</label>
      </div>
      <div class="col-md-2 footer-facturacion">
        <a class="font-weight-bold" (click)="showPopUp()">Facturación</a>
      </div>
      <div class="col-sm-12 col-md-3 footer-redes-sociales d-flex align-items-center">
        <div class="row fila-contenedor-redes">
          <div class="col-md-12 contenedor-redes">
            <div class="row">
              <div class="col-xs-4 col-sm-4 col-md-4 d-inline-block">
                <a target="_blank" href="https://www.facebook.com/EstrellaRojaPuebla/">
                  <img src="{{rutaIconoFacebook}}" />
                </a>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 d-inline-block">
                <a target="_blank" href="https://twitter.com/estrellarojapue">
                  <img src="{{rutaIconoX}}" alt="" />
                </a>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 d-inline-block">
                <a target="_blank" href="https://www.instagram.com/estrellaroja_puebla/">
                  <img src="{{rutaIconoInstagram}}" alt="" />
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</footer>
