import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map, share} from "rxjs/operators";
import {environment} from "../../environments/environment";
import Swal from "sweetalert2";

class Paquete {
  constructor(public id: number, public nombre) {
  }
}
@Injectable({
  providedIn: 'root'
})
export class PaquetesService {

  public reposObserver : Observable<Paquete[]>;

  constructor(private http: HttpClient) { }

  getPaquetes(){
    return this.http.get(environment.SIVER_API.URL + 'paquetecupon/available')
      .pipe( map((data : Object[]) => {
        return data.map((r : any) => r)
      }))
      .pipe(share())
      .pipe(catchError(this.handleError));
  }

  getPaquetesLogin(){
    let email = localStorage.getItem('1AzsRdds445ljGD');
    let telefono = localStorage.getItem('1AzsXdds445ljGD');
    return this.http.get(environment.SIVER_API.URL + 'paquetecupon/available/login?email=' + email + '&telefono=' + telefono)
      .pipe( map((data : Object[]) => {
        return data.map((r : any) => r)
      }))
      .pipe(share())
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    Swal.fire('Ups!', 'Ha ocurrido un al cargar los paquetes en este momento, intetar mas tarde <br> ', 'error')
    return throwError(errorMessage);
  }
}
