<div class="row">
  <div class="col-md-12 contenedor-pasos-ayuda">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <div class="titulo">
              Ayuda
            </div>
          </div>
          <div class="col-md-12" style="margin-top: 1%;">
            <div class="row">
              <div class="col-md-1">
              </div>
              <div class="col-md-1 contenedor-figura">
                <div class="figura">
                  1
                </div>
              </div>
              <div class="col-md-4 contenedor-texto">
                <div class="texto-normal">
                  Accede con los datos que te enviamos
                </div>
                <div class="texto-resaltado">
                  (teléfono y correo)
                </div>
              </div>
              <div class="col-md-1 contenedor-figura">
                <div class="figura">
                  2
                </div>
              </div>
              <div class="col-md-4 contenedor-texto">
                <div class="texto-normal">
                  Aprovecha las promociones<br>
                  personalizadas y viaja al mejor precio.
                </div>
              </div>
              <div class="col-md-1">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

  </div>
</div>
