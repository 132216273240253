import { Component, OnInit } from '@angular/core';
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {PaymentComponent} from "../modals/payment/payment.component";
import {PaquetesService} from "../../services/paquetes.service";
import {AppService} from "../../services/app.service";
import { ScriptService } from 'src/app/services/script.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faInfo, faBook } from '@fortawesome/free-solid-svg-icons';
import {RestriccionesComponent} from "../modals/restricciones/restricciones.component";
import {InstruccionesComponent} from "../modals/instrucciones/instrucciones.component";
import {AnalyticsService} from "../../services/analytics.service";
import {SharedService} from "../../services/shared.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  faInfo = faInfo;
  faBook = faBook;
  modalOptions:NgbModalOptions;
  categorias : string[] = [];
  categoriasUnicas : string[] = [];
  paquetes : any[] = [];
  paquetesFiltrados : any[] = [];
  titulos : string[] =[];
  email: string = localStorage.getItem('1AzsRdds445ljGD');

  constructor(private modalService: NgbModal,
              private sharedService: SharedService,
              public paquetesService: PaquetesService,
              private appService: AppService,
              private route: Router,
              private analytics: AnalyticsService) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'light-blue-backdrop',
      windowClass: 'modal-dialog-centered',
      size: 'lg'
    }
  }

  ngOnInit(): void {
    if (this.email !== null && this.email !== undefined){
      this.sharedService.showMenuLogin = false;
      this.route.navigate(['/homeuser']);
    } else {
      this.sharedService.showMenuLogin = true;
      this.appService.checkCredentials().then(value => {
        this.paquetesService.getPaquetes().subscribe(paquetesResult => {

          this.paquetes = paquetesResult;
          this.categorias.push('Todos');
          this.paquetes.forEach(p => {
            this.categorias.push(p.categoria.clave);
            p.titulos = p.nombre.split('|');
          });
          this.categoriasUnicas = Array.from(new Set(this.categorias));
          this.filtrarPaquetes(this.categoriasUnicas[0]);
        });
      });
    }
  }

  filtrarPaquetes(categoria){
    console.info(categoria);
    this.paquetesFiltrados = [];
    if(categoria === 'Todos'){
      this.paquetesFiltrados = this.paquetes;
    }else {
      this.paquetes.forEach(p => {
        if(p.categoria.clave === categoria){
          this.paquetesFiltrados.push(p);
        }
      });
    }
  }

  showInstrucciones(paquete) {
    let instrucciones = paquete.instruccionesCanje;
    const modalRef = this.modalService.open(InstruccionesComponent, this.modalOptions);
    modalRef.componentInstance.paquete = paquete;
    modalRef.componentInstance.instrucciones = instrucciones;
  }

  showRestricciones(paquete) {
    let restricciones = paquete.restricciones;
    const modalRef = this.modalService.open(RestriccionesComponent, this.modalOptions);
    modalRef.componentInstance.paquete = paquete;
    modalRef.componentInstance.restricciones = restricciones;
  }

  open(paquete) {
    //gtag_report_conversion();
    //dataLayer.push({'event': 'Prueba evento'});
    const modalRef = this.modalService.open(PaymentComponent, this.modalOptions);
    modalRef.componentInstance.paquete = paquete;
    modalRef.componentInstance.my_modal_content = 'I am your content';
  }

}
