import { Component, OnInit } from '@angular/core';
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {PaquetesService} from "../../../services/paquetes.service";
import {AppService} from "../../../services/app.service";
import { faInfo, faBook } from '@fortawesome/free-solid-svg-icons';
import {RestriccionesComponent} from "../../modals/restricciones/restricciones.component";
import {InstruccionesComponent} from "../../modals/instrucciones/instrucciones.component";
import {AnalyticsService} from "../../../services/analytics.service";
import {PaymentComponent} from "../../modals/payment/payment.component";
import {SharedService} from "../../../services/shared.service";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-home-venta-login',
  templateUrl: './home-venta-login.component.html',
  styleUrls: ['./home-venta-login.component.css']
})
export class HomeVentaLoginComponent implements OnInit {

  faInfo = faInfo;
  faBook = faBook;
  modalOptions:NgbModalOptions;
  categorias : string[] = [];
  categoriasUnicas : string[] = [];
  paquetes : any[] = [];
  paquetesFiltrados : any[] = [];
  titulos : string[] =[];
  eventsubscription: Subscription;
  email: string = localStorage.getItem('1AzsRdds445ljGD');
  constructor(private modalService: NgbModal,
              public paquetesService: PaquetesService,
              private appService: AppService,
              private sharedService: SharedService,
              private route: Router,
              private analytics: AnalyticsService) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'light-blue-backdrop',
      windowClass: 'modal-dialog-centered',
      size: 'lg'
    };
    this.eventsubscription = this.sharedService.getEvent().subscribe(() => {
      console.log('recargar paquetes al cerrar modal');
      this.getPaquetes();
    });
  }
  ngOnInit(): void {
    this.getPaquetes();
    if (this.email !== null && this.email !== undefined){
      this.sharedService.showMenuLogin = false;
    } else {
      this.route.navigate(['/home']);
      this.sharedService.showMenuLogin = true;
    }
  }

  ngAfterViewInit():void{
    var bodyElement = document.body;
    bodyElement.classList.add('login');
  }

  getPaquetes(){
    this.appService.checkCredentials().then(value => {
      this.paquetesService.getPaquetesLogin().subscribe(paquetesResult => {
        this.paquetes = paquetesResult;
        this.categorias.push('Todos');

        this.paquetes.forEach(p => {
          this.categorias.push(p.categoria.clave);
          p.titulos = p.nombre.split('|');
          p.titulosNormal = p.titulos.slice(0, 2);
          p.titulosBold = p.titulos.slice(2, p.titulos.length); // Extraer elementos desde la posición 3 hasta 'n'
        });
        this.categoriasUnicas = Array.from(new Set(this.categorias));
        this.filtrarPaquetes(this.categoriasUnicas[0]);
      });
    });
  }
  filtrarPaquetes(categoria){
    console.info(categoria);
    this.paquetesFiltrados = [];
    if(categoria === 'Todos'){
      this.paquetesFiltrados = this.paquetes;
    }else {
      this.paquetes.forEach(p => {
        if(p.categoria.clave === categoria){
          this.paquetesFiltrados.push(p);
        }
      });
    }
  }

  showInstrucciones(paquete) {
    let instrucciones = paquete.instruccionesCanje;
    const modalRef = this.modalService.open(InstruccionesComponent, this.modalOptions);
    modalRef.componentInstance.paquete = paquete;
    modalRef.componentInstance.instrucciones = instrucciones;
  }

  showRestricciones(paquete) {
    let restricciones = paquete.restricciones;
    const modalRef = this.modalService.open(RestriccionesComponent, this.modalOptions);
    modalRef.componentInstance.paquete = paquete;
    modalRef.componentInstance.restricciones = restricciones;
  }

  open(paquete) {
    const modalRef = this.modalService.open(PaymentComponent, this.modalOptions);
    modalRef.componentInstance.paquete = paquete;
    modalRef.componentInstance.my_modal_content = 'I am your content';
  }

  receiveMessage(message) {
   console.log('evento recibido');
  }
}
