import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaymentComponent } from './components/modals/payment/payment.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './components/home/home.component';
import {HttpInterceptorService} from "./services/http-interceptor.service";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {MaskPipe} from "ngx-mask";
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { RestriccionesComponent } from './components/modals/restricciones/restricciones.component';
import { CarruselComponent } from './components/carrusel/carrusel.component';
import { InstruccionesComponent } from './components/modals/instrucciones/instrucciones.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import {AppRoutes} from "./app.routes";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { CarruselfooterComponent } from './components/carruselfooter/carruselfooter.component';
import { PdfComponent } from './components/modals/pdf/pdf.component';
import { LoginComponent } from './components/modals/login/login.component';
import { HomeVentaLoginComponent } from './components/login/home-venta-login/home-venta-login.component';
import { FooterVentaLoginComponent } from './components/login/footer-venta-login/footer-venta-login.component';
import { LogosComponent } from './components/login/logos/logos.component';
import { CarruselVentaLoginComponent } from './components/login/carrusel-venta-login/carrusel-venta-login.component';
import { HomeLoginInvitationComponent } from './components/login/home-login-invitation/home-login-invitation.component';
import { HeaderLoginComponent } from './components/login/header-login/header-login.component';
import { AyudaPasosComponent } from './components/login/ayuda-pasos/ayuda-pasos.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    PaymentComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    RestriccionesComponent,
    CarruselComponent,
    InstruccionesComponent,
    ContactoComponent,
    CarruselfooterComponent,
    PdfComponent,
    LoginComponent,
    HomeVentaLoginComponent,
    FooterVentaLoginComponent,
    LogosComponent,
    CarruselVentaLoginComponent,
    HomeLoginInvitationComponent,
    AyudaPasosComponent,
    HeaderLoginComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxMaskModule.forRoot(maskConfig),
    AppRoutes,
    BrowserAnimationsModule
  ],
  entryComponents:[
    PaymentComponent
  ],
  providers: [
    MaskPipe,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
