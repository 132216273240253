import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faShoppingCart, faCreditCard, faBackward } from '@fortawesome/free-solid-svg-icons';
import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons';
import { CardService } from '../../../services/card.service';
import { v4 as uuid } from 'uuid';
import { VentapaqutesService } from '../../../services/ventapaqutes.service';
import { AppService } from '../../../services/app.service';
import { PaquetesService } from '../../../services/paquetes.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { IpService } from '../../../services/ip.service';
import { CookieService } from 'ngx-cookie-service';
import { RestriccionesComponent } from '../restricciones/restricciones.component';
import { UtilsService } from 'src/app/services/utils.service';
import { PartnersPagosService } from 'src/app/services/partnerspagos.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PdfComponent } from '../pdf/pdf.component';
import {HomeVentaLoginComponent} from '../../login/home-venta-login/home-venta-login.component';
import {SharedService} from "../../../services/shared.service";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  paymenntForm: FormGroup;
  @Input() paquete;
  @Input() my_modal_content;
  faShoppingCart = faShoppingCart;
  faBackward = faBackward;
  public cardIcon: any;
  public payPackage = false;
  submitted = false;
  public maxDate = moment(new Date()).format('YYYY-MM-DD');
  public minDate = moment(new Date().setMonth(new Date().getMonth() - 1800)).format('YYYY-MM-DD');
  private ip: any;
  public isPagoTarjeta = true;
  public isPagoEfectivo = false;
  public aceptaPagoEfectivo = false;
  public paquetePartnerId: number;
  public partnersPagosEfectivo: any[];
  public longitudCvv = 3;
  public mascaraCvv = '000';
  modalOptions: NgbModalOptions;
  responseTemplate;
  isVentaLogin = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder,
              private cardService: CardService, private ventapaqutesService: VentapaqutesService,
              private appService: AppService, private paquetesService: PaquetesService,
              private ipService: IpService, private cookieService: CookieService,
              private modalService: NgbModal, private utilsService: UtilsService,
              private partnerService: PartnersPagosService,
              private sharedService: SharedService) {
      this.modalOptions = {
          backdrop: 'static',
          backdropClass: 'light-blue-backdrop',
          windowClass: 'modal-dialog-centered',
          size: 'lg'
      };
      this.createPaymentForm();
  }

  createPaymentForm() {
      const email = localStorage.getItem('1AzsRdds445ljGD');
      if (email !== null){
        this.isVentaLogin = true;
      }
      this.paymenntForm = this.formBuilder.group({
          nombreCliente: ['', [Validators.required, Validators.pattern('[a-zA-Z ñÑá-úÁ-Ú.]*')]],
          correo: [email !== undefined ? email : '', [Validators.required, Validators.email]],
          telefono: ['', Validators.required],
          codigoPostal: ['', [Validators.required]],
          numeroTarjeta: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(16)]],
          fechaExpiracion: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
          cvv: [null, [Validators.required]],
          birhDate: [null, [Validators.required]],
          tipoTarjeta: ['UNDEFINED'],
          esValida: [false],
          lmsCliente: [''],
          aceptaTerminos: [false],
          paquetePartnerId: [''],
      });
      this.cardIcon = faCreditCard;
  }

  ngOnInit(): void {
      this.ipService.getIPAddress().subscribe(value => {
          this.ip = value;
      });
      this.getPartnerPagosEfectivo();
  }

  async getPartnerPagosEfectivo() {

      this.partnerService.getPaquetesPartnersByCuponId(this.paquete.id).subscribe(
          data => {
              this.partnersPagosEfectivo = data;
              if (this.partnersPagosEfectivo.length > 0) {
                  this.aceptaPagoEfectivo = true;
              } else {
                  this.aceptaPagoEfectivo = false;
              }
          }
      );

  }
  // convenience getter for easy access to form fields
  get f() {
      return this.paymenntForm.controls;
  }

  payment() {
      const fechaRecibida: any = document.getElementById('birhDate');
      if (this.validarFechaMenorActual(fechaRecibida.value, 'blur') == false) {
          return;
      }
      this.submitted = true;
      // stop here if form is invalid
      if (this.paymenntForm.invalid) {
          return;
      } else if (this.isPagoEfectivo && this.paquetePartnerId === undefined) {
          Swal.fire('Ups!', 'Debe seleccionar un partner en el cual realizará el pago en efectivo');
          this.paquetePartnerId = undefined;
          this.submitted = false;
          return;
      }
      this.obtenerTipoTarjeta();
      this.validarTarjeta();


      this.payPackage = true;
      this.appService.checkCredentials().then(value => {

          const salt = uuid();
          if (this.isPagoTarjeta) {
              if (this.paymenntForm.value.tipoTarjeta === 'UNDEFINED') {
                  Swal.fire('Ups!', 'Tarjeta no valida', 'error');
                  return;
              }
              const creditCard = {
                  number: this.paymenntForm.value.numeroTarjeta,
                  securityCode: this.paymenntForm.value.cvv,
                  expirationDate: '20' + this.paymenntForm.value.fechaExpiracion.substring(2, 4) + '/' + this.paymenntForm.value.fechaExpiracion.substring(0, 2),
                  name: this.paymenntForm.value.nombreCliente
              };
              this.paymenntForm.value.hash = this.cardService.encriptarDatos(creditCard, salt);
          } else {
              this.paymenntForm.value.tipoTarjeta = 'EFECTIVO';
              this.paymenntForm.value.paquetePartnerId = this.paquetePartnerId;
          }
          this.paymenntForm.value.salt = salt;
          this.paymenntForm.value.paquete = this.paquete;

          try {
              if (this.ip.ip != undefined) {
                  this.paymenntForm.value.ipAddress = this.ip.ip;
              } else {
                  this.paymenntForm.value.ipAddress = 'notfound';
              }
          } catch (e) {
              console.error('Error al obtener la ip: ', e);
              this.paymenntForm.value.ipAddress = 'notfound';
          }

          this.paymenntForm.value.deviceSessionId = this.cookieService.get('access_token');
          this.paymenntForm.value.userAgent = navigator.userAgent;
          this.ventapaqutesService.sendVentaPaquete(this.paymenntForm.value).subscribe(value => {
              const respuesta: any = value;
              let hasLmsCliente = false;
              let mensajeAcumulacion = '';
              let mensajeInstruccion = '';
              if (this.utilsService.isSuccessResponse(value) && this.utilsService.validarObjeto(respuesta.data) && this.utilsService.validarObjeto(respuesta.data.hasLmsCliente)) {
                  hasLmsCliente = respuesta.data.hasLmsCliente;
              }
              if (hasLmsCliente) {
                  if (respuesta.data.successAcumulacion) {
                      mensajeAcumulacion = 'Acumulaste el 10% de tu compra en Punto Abordo';
                  } else {
                      mensajeAcumulacion = 'No se ha podido acumular el 10% en Punto Abordo, para mayor información, déjanos tu duda en la sección "Contáctanos"';
                  }
              }
              this.payPackage = false;
              if (this.paquete.totalPaquetesDisponibles > 1) {
                  this.paquete.totalPaquetesDisponibles = this.paquete.totalPaquetesDisponibles - 1;
              }
              let template = '';
              if (this.paquetePartnerId == null) {
                  template = 'Tu compra del <strong style="color: #005cb9;">' + this.paquete.nombre + '</strong> ha sido exitosa. Recibirás en tu correo electrónico <strong style="color: #005cb9;">' + this.paymenntForm.value.correo + '</strong> el código promocional que podrás ocupar para canjearlo en nuestro sitio web o app. <br><div style="margin-top:10px">' + mensajeAcumulacion + '</div>';
              } else {
                  template = respuesta.data;
                  mensajeInstruccion = 'Recibirás la ficha de pago en tu correo para poder concluir la transacción en el establecimiento seleccionado.';
              }
              Swal.fire({
                  title: '¡Felicidades! <br>' + mensajeInstruccion,
                  html: template,
                  imageUrl: '../../../../../assets/img/gift.png',
                  imageHeight: 70,
                  imageWidth: 70,
                  width: 530
              });
              this.cleanInfoAndCloseModal();
          }, error => {
              this.payPackage = false;
              Swal.fire('Ups!', 'Ha ocurrido un error al comprar tu paquete <br> ' + error.error.message, 'error');
          });
      });
  }

  obtenerTipoTarjeta() {
      this.paymenntForm.value.tipoTarjeta = this.cardService.obtenerTipoTarjeta(this.paymenntForm.value.numeroTarjeta);
      this.longitudCvv = 3;
      this.mascaraCvv = '000';
      const oCvv: any = document.getElementById('cvv');
      if (oCvv != null){
        oCvv.value = '';
      }
      if (this.paymenntForm.value.tipoTarjeta === 'VISA') {
          this.cardIcon = faCcVisa;
      } else if (this.paymenntForm.value.tipoTarjeta === 'MASTERCARD') {
          this.cardIcon = faCcMastercard;
      } else if (this.paymenntForm.value.tipoTarjeta === 'AMEX') {
          this.longitudCvv = 4;
          this.mascaraCvv = '0000';
          this.cardIcon = faCcAmex;
      } else {
          this.cardIcon = faCreditCard;
      }
  }

  validarTarjeta() {
      this.paymenntForm.value.esValida = this.cardService.esTarjetaValida(this.paymenntForm.value.numeroTarjeta);
  }

  cleanInfoAndCloseModal() {
    if (this.paquete.tipoBonobus === 1){
      this.sharedService.sendEvent();
    }
    this.payPackage = false;
    this.activeModal.close('Close click');
  }

  showRestricciones(paquete) {
      const restricciones = paquete.restricciones.split('*');
      const modalRef = this.modalService.open(RestriccionesComponent, this.modalOptions);
      modalRef.componentInstance.paquete = paquete;
      modalRef.componentInstance.restricciones = restricciones.filter(Boolean);
  }

  seleccionarTipoPago(tipoPago, ) {
      if (tipoPago === 'tarjeta') {
          this.isPagoTarjeta = true;
          this.isPagoEfectivo = false;
          this.paymenntForm.controls.numeroTarjeta.setValidators([Validators.required, Validators.minLength(14), Validators.maxLength(16)]);
          this.paymenntForm.get('numeroTarjeta').updateValueAndValidity();
          this.paymenntForm.get('fechaExpiracion').setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
          this.paymenntForm.get('fechaExpiracion').updateValueAndValidity();
          this.paymenntForm.get('cvv').setValidators([Validators.required]);
          this.paymenntForm.get('cvv').updateValueAndValidity();
          this.paymenntForm.get('paquetePartnerId').setValue('');
          this.paquetePartnerId = undefined;
      } else {
          this.isPagoTarjeta = false;
          this.isPagoEfectivo = true;
          this.paymenntForm.get('paquetePartnerId').updateValueAndValidity();
          this.paymenntForm.get('numeroTarjeta').clearValidators();
          this.paymenntForm.get('numeroTarjeta').setValue('');
          this.paymenntForm.get('numeroTarjeta').updateValueAndValidity();
          this.paymenntForm.get('fechaExpiracion').clearValidators();
          this.paymenntForm.get('fechaExpiracion').setValue('');
          this.paymenntForm.get('fechaExpiracion').updateValueAndValidity();
          this.paymenntForm.get('cvv').clearValidators();
          this.paymenntForm.get('cvv').setValue('');
          this.paymenntForm.get('cvv').updateValueAndValidity();
      }

  }
  seleccionarPartnerPagoEfectivo(partnerId: number) {
      this.paquetePartnerId = partnerId;
  }
  htmltoPDF() {
      // parentdiv is the html element which has to be converted to PDF
      html2canvas(this.responseTemplate).then(canvas => {

          const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);

          const imgData = canvas.toDataURL('image/jpeg', 1.0);
          pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
          pdf.save('converteddoc.pdf');

      });
  }

  validarFechaMenorActual(fechaRecibida: any, tipo: string): boolean {
      const fechaEvaluar: Date = new Date();
      if (fechaRecibida.length == 10) {
          return this.fechaValidacion(fechaRecibida, tipo);
      } else if (fechaRecibida.length > 10) {
          const obirhDate: any = document.getElementById('birhDate');
          const aBirthDate = obirhDate.value.split('-');
          if (aBirthDate[0].length > 4) {
              aBirthDate[0] = aBirthDate[0].substring(0, 4);
              obirhDate.value = aBirthDate.join('-');
          }
          Swal.fire('Ups!', 'No puede ingresar un año de más de 5 digitos, coloque un año valido', 'error');
      }
  }

  fechaValidacion(fechaRecibida: String, evento: string): boolean {
      const fechaEvaluar: Date = new Date();
      const obirhDate: any = document.getElementById('birhDate');
      if (fechaRecibida.length == 10) {
          const aFechaPartes: Array < string > = fechaRecibida.split('-');
          const fechaActual: Date = new Date();
          const fechaMinima: Date = new Date();

          fechaEvaluar.setFullYear(parseInt(aFechaPartes[0]), parseInt(aFechaPartes[1]) - 1, parseInt(aFechaPartes[2]));
          fechaMinima.setMonth(fechaMinima.getMonth() - 1800);

          if (fechaEvaluar >= fechaActual) {
              Swal.fire('Ups!', 'Seleccione una fecha valida', 'error');
              fechaRecibida = '';
          } else if (fechaEvaluar <= fechaMinima) {
              if (evento == 'blur') {
                  Swal.fire('Ups!', 'Seleccione una fecha valida', 'error');
              }
          } else {
              return true;
          }
          return false;
      }
  }

}
