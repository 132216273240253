import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {catchError, map, share} from "rxjs/operators";
import Swal from "sweetalert2";

class Paquete {
  constructor(public id: number, public nombre) {
  }
}
@Injectable({
  providedIn: 'root'
})
export class UsuariobonobusService {

  public reposObserver: Observable<Paquete[]>;

  constructor(private http: HttpClient) {
  }

  login(email, telefono) {
    return this.http.get(environment.SIVER_API.URL + 'usuariobonobus/login?email=' + email + '&telefono=' + telefono)
      .pipe(map((data: any) => {
        console.log(data);
        localStorage.setItem('1AzsRdds445ljGD', data.data);
        localStorage.setItem('1AzsXdds445ljGD', telefono);
      }))
      .pipe(share())
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (error.error.responseCode === 500){
      errorMessage = `${error.error.message}`;
      Swal.fire('Ups!', errorMessage, 'error');
    } else {
      console.error(errorMessage);
      Swal.fire('Ups!', 'Ha ocurrido un al realizar el login, intetar mas tarde <br> ', 'error')
    }
    return throwError(errorMessage);
  }
}
