<app-header></app-header>
<app-carrusel-venta-login></app-carrusel-venta-login>
<app-logos></app-logos>
<!-- Paquetes -->
<section class="page-section section-info" id="info">
  <div class="container" data-aos="fade-right" style="margin-top: 1em;">
    <div class="text-center">
      <h2 class="section-heading " id="bonobusTitulo">UN BONO BUS HECHO A TU MEDIDA</h2>
      <h3 class="section-subheading margin-subheading">
        <p align="justify" style="line-height: 108%; margin-bottom: 0.28cm">
          <font>
            En er te consentimos con un bono bus personalizado, pensado en ti y en tus necesidades de viaje. 
          </font>
        </p>
        <p align="justify" style="line-height: 108%; margin-bottom: 0.28cm">
          <font>
            Recuerda que, al hacer tus compras como usuario registrado, podemos conocerte mejor y ofrecerte promociones tan únicas como tú.
          </font>
        </p>
        <p align="justify" style="line-height: 108%; margin-bottom: 0.28cm">
          <font>
            Con estrella roja, la forma en la que viajas sí importa.
          </font>
        </p>
      </h3>
    </div>
  </div>
</section>


<section class="page-section" id="paquetes">
  <div class="container margin-bonobus" data-aos="fade-right">

    <ul *ngIf="paquetesFiltrados.length > 0" class="nav nav-tabs justify-content-center border-venta-login" id="pills-tab" role="tablist">
      <li *ngFor="let categoria of categoriasUnicas" class="nav-item">
        <a class="nav-link tabs-categoria" [ngClass]="{'active' : categoria === 'Todos'}"
           id="{{categoria.trim() + '-tab'}}" data-toggle="pill" href="{{'#'+categoria.trim()}}" role="tab"
           aria-controls="pills-1" aria-selected="true" 
           (click)="filtrarPaquetes(categoria)"><b>{{categoria}}</b></a>
      </li>
    </ul>

    <h5 *ngIf="paquetesFiltrados.length === 0" class="text-center margin-top-mxn font-weight-light" >
      ¡Ya has comprado tu bono bus! Espera nuevas promociones en tu correo o teléfono.
    </h5>

    <div class="card-deck mb-3 text-center" style="margin-top: 1em;">
      <div *ngFor="let paquete of paquetesFiltrados" data-aos="zoom-in-left"
           class="card mb-4 overflow-hidden card-container">
        <div class="card-header">
          <img [src]="'assets/img/' + paquete.servicio + '.png'" alt="" height="60px;">
          <h4 class="font-weight-normal" *ngFor="let titulo of paquete.titulosNormal">{{titulo}}</h4>
          <h4 class="my-0 font-weight-normal font-weight-bold color-bold" *ngFor="let titulo of paquete.titulosBold">{{titulo}}</h4>
          <h4 class="my-0 font-weight-normal text-black-100 font-weight-bold">{{paquete.categoria.clave}}</h4>
        </div>
        <div class="center-body">
          <div class="col-md-11 col-sm-11 card-body card-venta-login ">
            <div class="col-md-12 center-body text-white">
              <label class="font-family-bold font-size-price">{{paquete.precioPromocion | currency}}</label>
              <label class="font-family-bold margin-top-mxn">&nbsp;mxn</label>
            </div>
            <div class="col-md-12 center-body text-white">
              <label class="font-weight-bold"> Precio original: {{paquete.precioOriginal | currency}} MXN</label>
            </div>
            <div class="col-md-12 center-body text-white">
              <label class="font-weight-bold">Disponibles: <b class="font-family-bold">{{paquete.totalPaquetesDisponibles | number}}</b></label>
            </div>
            <div class="col-md-12 center-body text-white">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <input (click)="showRestricciones(paquete)" class="btn text-white text-decoration-underline font-italic" type="button"
                         value="Términos y Condiciones">
                </div>
                <div class="col-md-6 col-sm-12">
                  <input (click)="showInstrucciones(paquete)" class="btn text-white text-decoration-underline font-italic" type="button"
                         value="Instrucciones de Canje">
                </div>
              </div>
            </div>
            <ul class="list-unstyled mt-3 mb-4 ">
              <li>
              </li>
              <li>
              </li>
            </ul>
            <div class="col-md-12">
              <button type="button" class="btn btn-lg btn-block btn-light" (click)="open(paquete)">Comprar</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
<app-carruselfooter></app-carruselfooter>
<app-footer-venta-login></app-footer-venta-login>
