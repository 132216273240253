<nav class="navbar navbar-expand-lg fixed-top" id="mainNav">
  <div class="container">
    <a class="navbar-brand js-scroll-trigger" href="#page-top">
      <img src="assets/img/er.svg" alt="" />
    </a>
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
      data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
      aria-label="Toggle navigation">Menu<i class="fas fa-bars ml-1"></i></button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav text-uppercase ml-auto font-weight-bold text-black-50">
        <li [hidden]="(!sharedService.showMenuLogin)" class="nav-item"><a id="iniciarSesion"
            class="nav-link js-scroll-trigger btn btn-lg" href="#" (click)="showModalLogin()">
            Iniciar Sesión
          </a></li>
        <li [hidden]="(sharedService.showMenuLogin)" class="nav-item"><a id="fakeIniciarSesion"
            class="nav-link js-scroll-trigger btn btn-lg" href="#">
            Iniciaste sesión con éxito.
          </a></li>
        <li [hidden]="(sharedService.showMenuLogin)" class="nav-item" style="padding-top: 5px; text-transform: none;"><a class="nav-link js-scroll-trigger" href="#"
            (click)="closeSession()">Cerrar Sesión</a></li>
        <li [hidden]="(!sharedService.showMenuLogin)" class="nav-item"><a class="nav-link js-scroll-trigger"
            href="#paquetes">Paquetes</a></li>
        <li [hidden]="(!sharedService.showMenuLogin)" class="nav-item"><a class="nav-link js-scroll-trigger"
            href="#contacto">Regístrate</a></li>
      </ul>
    </div>
  </div>
</nav>
